import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const data = [
  { title: "One 30 minute lesson per week", price: "$38" },
  { title: "One 45 minute lesson per week", price: "$49" },
  { title: "One 60 minute lesson per week", price: "$66" },
]

const IndexPage = () => (
  <Layout>
    <SEO title="Tuition" />

    <section className="pb-10 max-w-3xl mx-auto">
      <h1 className="text-3xl lg:text-4xl text-center pb-6">Tuition</h1>

      <div className="w-full">
        <div className="w-full overflow-auto bg-yellow-light-a30 shadow border border-gray-light rounded p-4">
          <table className="table-auto w-full">
            <thead>
              <tr className="text-left">
                <th className="border-b-2 border-gray-light p-2">Plan</th>
                <th className="border-b-2 border-gray-light p-2">Price</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={`tuition-${index}`}
                  className={index % 2 ? "" : "bg-yellow-light-a40"}
                >
                  <td className="border-t border-gray-light px-2 py-3">
                    {item.title}
                  </td>
                  <td className="border-t border-gray-light px-2 py-3">
                    {item.price}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <p className="text-center leading-loose pb-6 pt-6 text-md">
          The recommended lesson length for beginners is 30 minutes.
          <br />
          Zoom lessons are available as well.
        </p>
      </div>

      <div className="w-full leading-loose">
        <h3 className="text-lg md:text-xl font-semibold pt-8 pb-2">Payment</h3>
        <ul className="list-disc ml-5">
          <li>
            Payment is required in full prior to the beginning of each Quarter
            or by the !st lesson.
          </li>
          <li>
            There is a <b>$25 late fee</b> for payments made after the 1st week.
          </li>
          <li>
            Canceled check fee - <b>$25</b>
          </li>
        </ul>
      </div>

      <div className="w-full leading-loose">
        <h3 className="text-lg md:text-xl font-semibold pt-8 pb-2">
          Cancelled Lessons
        </h3>
        <ul className="list-disc ml-5">
          <li>Your lesson is a standing commitment.</li>
          <li>
            Missed lessons or short-notice cancellations will not be made up or
            reimbursed.
          </li>
          <li>
            You may only cancel a lesson in case of an illness or family
            emergency.
          </li>
          <li>
            Please do not bring your child if they are running a fever, or are
            showing symptoms of an illness.
          </li>
          <li>
            Lessons cancelled by the teacher will be made up or credited the
            following month.
          </li>
        </ul>
      </div>

      <div className="w-full leading-loose">
        <h3 className="text-lg md:text-xl font-semibold pt-8 pb-2">
          Student Absences & Makeups
        </h3>
        <ul className="list-disc ml-5">
          <li>
            I require at least a 24-hour notice in advance to receive a makeup
            (exceptions for illnesses). No makeup is given for lateness.
          </li>
          <li>Students are granted 2 makeups per each quarter.</li>
          <li>
            If students cancel their makeup lesson, the lesson is considered
            made-up.
          </li>
          <li>No credits are given.</li>
        </ul>
      </div>
      <div className="w-full leading-loose">
        <h3 className="text-lg md:text-xl font-semibold pt-8 pb-2">
          Termination of Lessons
        </h3>
        <p>
          If you would like to withdraw from piano lessons for any reason, at
          least a one-month notice in advance is required from the termination
          date.
        </p>
      </div>
    </section>
  </Layout>
)

export default IndexPage
